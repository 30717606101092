import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: null
  },
  mutations: {
    setId(state, value) {
      state.id = value
    }
  }
})
