<template>
  <div>

    <!-- Start notification -->
    <successNotification v-if="showSuccessNotification"></successNotification>
    <failNotification v-if="showFailNotification"></failNotification>
    <!-- Modal Alert -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <p>اليوم المحدد غير متاح للحجز، الرجاء اختيار يوم آخر.</p>
        <button class="bg-[#DA4403] p-1 m-2 rounded-md text-zinc-50" @click="closeModal">إغلاق</button>
      </div>
    </div>
    <!-- End Modal -->

    <div class="flex relative">
      <menuComponent class="w-1/6"></menuComponent>
      <div class="left-0 absolute w-5/6 px-12">
        <headerComponent></headerComponent>
        <div class="mt-20 mb-10">
          <h3 class="text-3xl font-bold mb-2 text-center">اهلا بكم في بوابة حجز الاجتماعات </h3>
          <!-- <h3 class="text-3xl font-bold mb-2">حجز اجتماع جديد خارجي</h3> -->
          <h5 class="text-xl  text-slate-600 text-center py-3">يمكنك حجز اجتماعك بسهولة وسرعة من هنا</h5>


          <div class="border rounded-lg">
            <div class="w-fit mx-auto my-16 space-y-10">

              <!-- FIRST ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-secondary" for="entityType">نوع الجهة</label>
                  <div class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="entityType" id="entityType" v-model="entityType">
                      <option value="خارجي">خارجي</option>
                    </select>
                  </div>
                </div>

                <div class="space-y-2">
                  <label class="text-secondary" for="entityName">اسم الجهة</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="entityName" id="entityName" placeholder="ادخل اسم الجهة"
                      v-model="entityName" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('entityName') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityName == '' && errors.first('entityName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF FIRST ROW OF THE FORM -->

              <!-- SECOND ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-secondary" for="reservationHolderName">اسم صاحب الحجز</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="reservationHolderName" id="reservationHolderName"
                      placeholder="اسم صاحب الحجز" v-model="reservationHolderName" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('reservationHolderName') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityActivity == '' && errors.first('reservationHolderName') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class="space-y-2">
                  <label class="text-secondary" for="entityActivity">نشاط الجهة</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild" name="entityActivity" id="entityActivity"
                      placeholder="نشاط الجهة" v-model="entityActivity" v-validate="'required|alpha_spaces'">
                  </div>
                  <p class="error">{{ errors.first('entityActivity') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="entityActivity == '' && errors.first('entityActivity') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF SECOND ROW OF THE FORM -->

              <!-- THIRD ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-secondary" for="phoneNumber">رقم الجوال</label>
                  <div class="w-[360px]">
                    <input type="text" class="inputFild text-right" dir="ltr" name="phoneNumber" id="phoneNumber"
                      placeholder="05" v-model="phoneNumber" v-validate="'required|numeric|min:10|max:10'">
                  </div>
                  <p class="error">{{ errors.first('phoneNumber') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="phoneNumber == '' && errors.first('phoneNumber') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class="space-y-2">
                  <label class="text-secondary" for="meetingRoom">اختر القاعة</label>
                  <div @change="available_hours(); timeRange()" class="w-[360px]">
                    <select class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="meetingRoom" id="meetingRoom" v-model="meetingRoom">
                      <option v-for="(room, index) in meetingRooms" :key="index" :value="room">{{ room }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- END OF THIRD ROW OF THE FORM -->

              <!-- 4 ROW OF THE FORM -->
              <div class="flex justify-start gap-4">
                <div class="space-y-2">
                  <label class="text-secondary" for="meetingDate">تاريخ الاجتماع</label>
                  <div>
                    <input @change="checkUnavailableDates" @input="disableSelectedDays" class="inputFild" type="date" name="meetingDate"
                      id="meetingDate" placeholder="حدد وقت نهاية العمل كل يوم" v-model="meetingDate" :min="minDate"
                      v-validate="'required'">
                  </div>
                  <p class="error">{{ errors.first('meetingDate') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meetingDate == '' && errors.first('meetingDate') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>

                <div class="space-y-2">
                  <label class="text-secondary" for="meeting_duration">اختر مدة الاجتماع</label>
                  <div class="w-[360px]">
                    <select :disabled="disableLastInputs" @change="deleteStartTime" @click="removePastTimes"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10"
                      name="meeting_duration" id="meeting_duration" v-model="meeting_duration" v-validate="'required'">
                      <option v-for="(d) in duration" :key="d.value" :value="d.value">{{ d.time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('meeting_duration') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="meeting_duration == '' && errors.first('meeting_duration') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF 4 ROW OF THE FORM -->

              <!-- 5 ROW OF THE FORM -->
              <div class="flex justify-center gap-4">
                <div class="space-y-2">
                  <label class="text-secondary" for="startTime">اختر وقت بداية الاجتماع</label>
                  <div class="w-[360px]">
                    <select @focus="filteredStartTimes" @change="filteredEndTimes" :disabled="disableIfNoDuration"
                      class="w-[360px] border-[#D5D5D5] border-[1px] rounded bg-[#F5F6FA] px-2 h-10" name="startTime"
                      id="startTime" v-model="startTime" v-validate="'required'">
                      <option v-for="(time, index) in filteredTimes" :key="index" :value="time"
                        style="direction: ltr; text-align: right;">{{ time }}
                      </option>
                    </select>
                  </div>
                  <p class="error">{{ errors.first('startTime') }}</p>
                  <div v-if="showValidationError">
                    <p v-if="startTime == '' && errors.first('startTime') == null" class="error">
                      يرجى تعبئة الحقل
                    </p>
                  </div>
                </div>
              </div>
              <!-- END OF 5 ROW OF THE FORM -->

              <div v-if="isLoading" class="flex justify-center items-center">
                <div class="text-center">
                    <div role="status" class="flex gap-2">
                        <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class=" text-gray-500 font-semibold">جاري الإرسال</span>
                    </div>
                </div>
              </div>

              <!-- Start Button -->
              <div class="text-center">
                <button @click="submitData"
                  class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg" :disabled="!isDateAllowed">إضافة</button>
              </div>
              <!-- End Button -->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerComponent from '@/components/headerSection.vue'
import successNotification from '@/components/Notification/successNotification.vue'
import failNotification from '@/components/Notification/failNotification.vue'
import filter from '@/mixins/filter.js'
import { supabase } from '../../../supabase';

export default {
  name: 'externalBooking',
  components: {
    headerComponent,
    successNotification,
    failNotification,
  },
  data() {
    return {
      identificationNumber: '',
      entityType: 'خارجي',
      entityName: '',
      reservationHolderName: '',
      entityActivity: '',
      phoneNumber: '',
      meetingRooms: [],
      meetingRoom: '',
      timeArray: [],
      filteredTimes: [],
      meetingDate: '',
      unavailableDates: [],
      duration: [],
      meeting_duration: '',
      startTime: '',
      endTime: '',
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,
      isLoading: false,
      disabledDays: [5], // 5 يمثل يوم الجمعة
      showModal: false,
      isDateAllowed: true, // صلاحية التاريخ
    }
  },
  watch: {
    meetingDate(newDate) {
      const selectedDate = new Date(newDate);
      const day = selectedDate.getDay();

      if (this.disabledDays.includes(day)) {
        // تعيين التاريخ كقيمة فارغة فورًا قبل عرض التنبيه
        this.meetingDate = ''; 
        this.$nextTick(() => {
          if (!this.showModal) {
            this.isDateAllowed = false;
            this.showModal = true;
          }
        });
      } else {
        this.isDateAllowed = true;
      }
    }
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },
    async submitData() {
      if (this.entityType && this.entityName && this.entityActivity && this.phoneNumber && this.meetingRoom
        && this.meetingDate && this.meeting_duration && this.startTime && this.endTime) {
        this.isLoading = true;

        try {
          const { data } = await supabase
            .from('Meeting')
            .insert([{ reservationHolderName: this.reservationHolderName, entityName: this.entityName, entityType: this.entityType, entityActivity: this.entityActivity, phoneNumber: this.phoneNumber, meetingRoom: this.meetingRoom, meetingDate: this.meetingDate, meeting_duration: this.meeting_duration, startTime: this.startTime, endTime: this.endTime, meetingStatus: 'معلق' }])
            .select();

          this.showSuccessNotification = true;
          setTimeout(async () => {
            this.showSuccessNotification = false;
            this.identificationNumber = data[0].identificationNumber;
            this.insertNotification();
            this.$router.push({ path: "/thankuExternal" });
          }, 1700);

        } catch (error) {
          console.error('Error inserting data:', error.message);
          this.showFailNotification = true;
          setTimeout(() => {
            this.showFailNotification = false;
            this.showValidationError = true;
          }, 1700);

        } finally {
          this.isLoading = false;
        }
      } else {
        this.showFailNotification = true;
        setTimeout(() => {
          this.showFailNotification = false;
          this.showValidationError = true;
        }, 1700);
        return;
      }
    },
    async insertNotification() {
      const notification = {
        by: sessionStorage.getItem('userName'),
        notificationType: 'newExMeeting',
        identificationNumber: this.identificationNumber,
        readed_by: []
      };

      await supabase
        .from('notification')
        .insert([notification])
        .then(result => {
          if (result.error) {
            console.error('Failed to send notification:', result.error);
          }
        }).catch(error => {
          console.error('Error sending notification:', error);
        });
    },
  },
  computed: {
    disableLastInputs() {
      return !(this.entityType && this.entityName && this.entityActivity && this.phoneNumber && this.meetingRoom && this.meetingDate);
    },
    disableIfNoDuration() {
      return !this.meeting_duration;
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  mixins: [filter],
  async mounted() {
    let { data: Hall } = await supabase
      .from('Hall')
      .select('hallName, hallStatus');
    this.meetingRooms.push(...Hall.filter(e => e.hallStatus === 'فعال').map(e => e.hallName));
  },
}
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>
