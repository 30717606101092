<template>
    <div class="bg-gray-100 min-h-screen flex items-center justify-center">
      <div class="bg-white shadow-lg rounded-lg p-28 text-center">
        <h1 class="text-3xl font-bold text-gray-800 mb-4">شكرًا لك</h1>
        <p class="text-gray-600 mb-10">لقد تم حجز طلبك بنجاح.</p>
        <div class="flex flex-col justify-center">

          <button @click="newReservation" class="bg-primary hover:bg-dark-primary text-white font-bold py-2 px-4 rounded">
          حجز جديد
        </button>
        <button @click="goWebsite" class="bg-primary mt-3 hover:bg-dark-primary text-white font-bold py-2 px-4 rounded">
          الرجوع للموقع الإلكتروني 
        </button>

        </div>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ThankYou',
    data() {
      return {
        identificationNumber: this.$route.params.id, // الحصول على الـ ID من الرابط المار من صفحة الحجز

      }

    },
    computed: {
      id() {
        const id = this.$store.state.id
        return id
      }
    },
    methods: {
      newReservation() {
        this.$router.push(`/newbooking/${this.$route.params.id}`);
      },
      goWebsite() {
        window.location.href = 'https://quickstep.sa';
      }

    }
  }
  </script>
  
  <style>
  .bg-primary {
    background-color: #00B69B;
  }
  
  .hover\:bg-dark-primary:hover {
    background-color: #00907C;
  }
  </style>