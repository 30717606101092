import { render, staticRenderFns } from "./externalBooking.vue?vue&type=template&id=0680aa84&scoped=true"
import script from "./externalBooking.vue?vue&type=script&lang=js"
export * from "./externalBooking.vue?vue&type=script&lang=js"
import style0 from "./externalBooking.vue?vue&type=style&index=0&id=0680aa84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0680aa84",
  null
  
)

export default component.exports