<template>
  <div class="p-10 flex flex-col items-center">
    <!-- Start notification -->
    <successNotification v-if="showSuccessNotification" />
    <failNotification v-if="showFailNotification" />
    <!-- Modal Alert -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <p>اليوم المحدد غير متاح للحجز، الرجاء اختيار يوم آخر.</p>
        <!-- <button @click="closeModal">إغلاق</button> -->
        <button class="bg-[#DA4403] p-1 m-2 rounded-md text-zinc-50" @click="closeModal">إغلاق</button>

      </div>
    </div>
    <!-- End notification -->

    <div v-if="beneficiaryData" class="overflow-x-hidden">
      <!-- Welcome message -->
      <div class="flex flex-col lg:flex-row gap-2 items-center lg:items-start">
        <h1 class="text-2xl font-bold text-center lg:text-right">مرحبا بكم {{ beneficiaryData.entityName }} في نظام الحجز السريع</h1>
        <img :class="{ 'w-10': beneficiaryData.profilePictureUrl }" :src="beneficiaryData.profilePictureUrl" alt="" class="mt-2 lg:mt-0">
      </div>
      <h2 class="text-[#9E9E9E] text-center lg:text-right">هنا يمكنكم الحجز لاجتماعاتكم بسهولة ويسر</h2>
      <div class="mt-10">
        <h3 class="text-3xl font-bold mb-2 text-center lg:text-right">حجز اجتماع جديد</h3>
      </div>
      <div class="lg:border lg:rounded-lg flex flex-col items-center p-4">
        <!-- Meeting booking form -->
        <div class="flex flex-col space-y-4 my-16 w-full lg:w-auto">
          <!-- First input pair -->
          <div class="flex flex-col sm:flex-row gap-4 w-full">
            <div class="flex flex-col my-4 w-full">
              <label class="text-secondary" for="meetingRoom">اختر القاعة</label>
              <div class="w-full lg:w-[360px]">
                <select @change="available_hours(); timeRange()" class="inputFild h-11 w-full" name="meetingRoom" id="meetingRoom" v-model="meetingRoom">
                  <option v-for="(room, index) in meetingRooms" :key="index" :value="room">
                    {{ room }}
                  </option>
                </select>
              </div>
              <div v-if="showValidationError">
                <p v-if="meetingRoom == null" class="error">
                  يرجى تعبئة الحقل
                </p>
              </div>
            </div>
            <div class="flex flex-col my-4 w-full">
              <label class="text-secondary" for="meetingDate">تاريخ الاجتماع</label>
              <div class="w-full lg:w-auto">
                <input @change="checkUnavailableDates" @input="disableSelectedDays" class="inputFild w-full" type="date" name="meetingDate" id="meetingDate" placeholder="حدد وقت نهاية العمل كل يوم" v-model="meetingDate" :min="minDate" />
              </div>
              <div v-if="showValidationError">
                <p v-if="meetingDate == ''" class="error">
                  يرجى تعبئة الحقل
                </p>
              </div>
            </div>
          </div>
          <!-- Second input pair -->
          <div class="flex flex-col sm:flex-row gap-4 w-full">
            <div class="flex flex-col my-2 w-full">
              <label class="text-secondary" for="meeting_duration">اختر مدة الاجتماع</label>
              <div class="w-full lg:w-[360px]">
                <select :disabled="disableLastInputs" @change="deleteStartTime" class="inputFild h-11 w-full" name="meeting_duration" id="meeting_duration" v-model="meeting_duration">
                  <option v-for="(d) in duration" :key="d.value" :value="d.value">{{ d.time }}</option>
                </select>
              </div>
              <div v-if="showValidationError">
                <p v-if="meeting_duration == ''" class="error">
                  يرجى تعبئة الحقل
                </p>
              </div>
            </div>
            <div class="flex flex-col my-2 w-full">
              <label class="text-secondary" for="startTime">اختر وقت بداية الاجتماع</label>
              <div class="w-full lg:w-[360px]">
                <select @focus="filteredStartTimes" @change="filteredEndTimes" :disabled="disableIfNoDuration" class="inputFild h-11 w-full" name="startTime" id="startTime" v-model="startTime">
                  <option v-for="(time, index) in filteredTimes" :key="index" :value="time" style="direction: ltr; text-align: right;">
                    {{ time }}
                  </option>
                </select>
              </div>
              <div v-if="showValidationError">
                <p v-if="startTime == ''" class="error">
                  يرجى تعبئة الحقل
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center gap-4">
          <div>
            <p v-if="this.startTime && this.endTime">بداية وقت الاجتماع الساعة {{ this.startTime }} وينتهي الساعة {{ this.endTime }}</p>
          </div>
        </div>
        <div v-if="isLoading" class="flex justify-center items-center my-7">
          <div class="text-center">
            <div role="status" class="flex gap-2">
              <svg aria-hidden="true" class="inline w-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="text-gray-500 font-semibold">جاري الإرسال</span>
            </div>
          </div>
        </div>
        <!-- Start Button -->
        <div class="text-center pb-6 pt-4">
          <button @click="submitData" class="btn_hover rounded-lg bg-gradient-to-b from-[#ED8F37] to-[#ED8F37A6] px-16 py-2 text-yellow-50 text-lg">تأكيد الحجز</button>
        </div>
        <!-- End Button -->
      </div>
    </div>

    <!-- WHILE IT LOADING THE USER DATA AND CHECK IF ID EXIST -->
    <div v-if="loading" class="flex items-center justify-center h-64 bg-white shadow-lg rounded-lg p-6 animate-fade-in">
      <div class="flex items-center space-x-4 gap-3">
        <div class="animate-spin rounded-full h-7 w-7 border-4 border-[#00B69B] border-t-transparent"></div>
        <span class="text-[#00B69B] font-semibold text-base">جاري تحميل الصفحة...</span>
      </div>
    </div>

    <div v-else-if="!beneficiaryData" class="flex items-center justify-center h-64 bg-white shadow-lg rounded-lg p-6">
      <div class="text-center space-y-4">
        <svg class="h-16 w-16 text-red-600 mx-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <p class="text-gray-600 font-medium text-xs leading-relaxed">
          نأسف، لا يوجد في سجلاتنا رقم تعريف مرتبط بهذا الطلب. يرجى الاتصال بفريق خدمة العملاء في شركة quick step
          للمساعدة في تحديد رقم التعريف الصحيح.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import successNotification from "@/components/Notification/successNotification.vue";
import failNotification from "@/components/Notification/failNotification.vue";
import { supabase } from "../../supabase";
import filter from "@/mixins/filter.js";

import { mapMutations } from 'vuex'

export default {
  name: "singleID",
  components: {
    successNotification,
    failNotification,
  },
  data() {
    return {
      identificationNumber: '',
      id: this.$route.params.id,
      beneficiaryData: null,
      showSuccessNotification: false,
      showFailNotification: false,
      showValidationError: false,

      meetingRoom: null,
      meetingRooms: [],
      meetingDate: '',
      meeting_duration: '',
      duration: [],

      timeArray: [],
      filteredTimes: [],

      unavailableDates: [],

      startTime: '',
      endTime: '',

      isLoading: false,
      reservationHolderName: '',
      entityName: '',
      entityType: '',
      phoneNumber: '',

      isDataLoaded: false,
      loading: true,

      showModal: false,
      disabledDays: [5] // منع اختيار يوم الجمعة
    };
  },
  mixins: [filter],
  watch: {
    meetingDate(newDate) {
      const selectedDate = new Date(newDate);
      const day = selectedDate.getDay();

      if (this.disabledDays.includes(day)) {
        this.meetingDate = ''; // مسح التاريخ فورًا
        this.showModal = true;
      }
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    async submitData() {
  if (this.isDataLoaded && this.entityType && this.entityName && this.meetingRoom && this.meetingDate && this.meeting_duration && this.startTime && this.endTime) {
    this.isLoading = true;
    try {
      const { data, error } = await supabase.from("Meeting").insert([{ 
        reservationHolderName: this.beneficiaryData.entityName, 
        entityName: this.entityName, 
        entityType: this.entityType, 
        phoneNumber: this.phoneNumber, 
        meetingRoom: this.meetingRoom, 
        meetingDate: this.meetingDate,
        meeting_duration: this.meeting_duration, 
        startTime: this.startTime, 
        endTime: this.endTime, 
        meetingStatus: "معلق" 
      }]).select();

      // تحقق من وجود بيانات مرتجعة وإظهار الإشعار للمستخدم
      if (data && data.length > 0) {
        this.showSuccessNotification = true;
        this.identificationNumber = data[0].identificationNumber;

        setTimeout(() => {
          this.showSuccessNotification = false;
          this.insertNotification();
          this.setId(this.id);
          this.$router.push({ path: `/thankYou/${this.$route.params.id}` });
        }, 1700);
      } else {
        // في حال عدم رجوع بيانات متوقعة بعد الإدخال
        this.showFailNotification = true;
        setTimeout(() => {
          this.showFailNotification = false;
          this.showValidationError = true;
        }, 1700);
      }
    } catch (error) {
      // إظهار رسالة تنبيه عامة في حال حدوث خطأ
      this.showFailNotification = true;
      setTimeout(() => {
        this.showFailNotification = false;
        this.showValidationError = true;
      }, 1700);
    } finally {
      this.isLoading = false;
    }
  } else {
    // إشعار المستخدم عند عدم تعبئة كل الحقول
    this.showFailNotification = true;
    setTimeout(() => {
      this.showFailNotification = false;
      this.showValidationError = true;
    }, 1700);
  }
},

async insertNotification() {
    const notification = {
      by: this.beneficiaryData.entityName,
      notificationType: 'newInMeeting',
      identificationNumber: this.identificationNumber,
      readed_by: []
    };

    try {
      const { error } = await supabase
        .from('notification')
        .insert([notification]);

      if (error) {
        console.error('Error sending notification:', error);
      }
    } catch (error) {
      console.error('Unexpected error during notification:', error);
    }
  },

    ...mapMutations(['setId']),
  },
  computed: {
    disableLastInputs() {
      return !(this.entityType && this.entityName && this.meetingRoom && this.meetingDate);
    },
    disableIfNoDuration() {
      return !this.meeting_duration;
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  async created() {
    try {
      const { data: Beneficiary, error } = await supabase
        .from("Beneficiary")
        .select("*")
        .eq("identificationNumber", this.id);

      if (error) {
        console.error("Error fetching beneficiary data:", error);
        this.loading = false;
        this.showFailNotification = true;
      } else if (!Beneficiary || Beneficiary.length === 0) {
        console.log("No data found for this ID.");
        this.loading = false;
      } else {
        this.beneficiaryData = Beneficiary[0];
        this.reservationHolderName = this.beneficiaryData.reservationHolderName;
        this.entityName = this.beneficiaryData.entityName;
        this.phoneNumber = this.beneficiaryData.phoneNumber;
        this.entityType = 'داخلي';
        this.isDataLoaded = true;
        this.loading = false;
      }
    } catch (e) {
      console.error("Unexpected error:", e);
      this.loading = false;
      this.showFailNotification = true;
    }
  },
  async mounted() {
    let { data: Hall } = await supabase.from("Hall").select("hallName, hallStatus");
    this.meetingRooms.push(...Hall.filter(e => e.hallStatus === "فعال").map(e => e.hallName));
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>
